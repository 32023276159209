var exports = {};

exports = function (obj) {
  var ret = {};
  var keys = Object.keys(Object(obj));

  for (var i = 0; i < keys.length; i++) {
    ret[keys[i].toLowerCase()] = obj[keys[i]];
  }

  return ret;
};

export default exports;